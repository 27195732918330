import { gql } from "@apollo/client";

const UPDATE_OUNIT = gql`
  mutation updateOUnit($oUnitData: OUnitInput!) {
    updateOUnit(oUnitData: $oUnitData) {      
        abbreviation
        ounit_name{
          name
          language
        }
        ounit_contact {
          contact_names
          emails
      }
    }
  }
`;

export default UPDATE_OUNIT;
