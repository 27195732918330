import { gql } from "@apollo/client";

const GET_OUNITS = gql`
query ounits($heiID: String!)
    {ounits(heiID: $heiID) {
        abbreviation
        ounit_id
        ounit_code
        ounit_name{
            name
            language
        }
        ounit_contact{
          contact_names
          emails
        }
    }  
}`
;

export default GET_OUNITS;
